import { NgModule, Optional, SkipSelf } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
/**
 * Classes.
 */
import { ResolverClass, DevicesClass } from "../classes";

/**
 * Services.
 */
import { GuardUserService, RolesService, SEOService, StorageService, TokenService } from "../services";
import { AuthService } from "@app/modules/auth-module/auth.service";

/**
 * Interceptors
 */
import { HttpRequestInterceptor } from "../interceptor/http.interceptor";

/**
 * Vendors
 */
import { CookieService } from "ngx-cookie-service";

@NgModule({
  declarations: [ResolverClass, DevicesClass],
  imports: [BrowserModule, BrowserAnimationsModule, HttpClientModule],
  exports: [RouterModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
    },
    CookieService,
    GuardUserService,
    RolesService,
    SEOService,
    StorageService,
    TokenService,
    AuthService,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }
}
