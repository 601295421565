import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewEncapsulation,
} from "@angular/core";
import { SEOService } from "@app/core/services";
import { TranslateService } from "@ngx-translate/core";
import { PrimeNGConfig } from "primeng/api";

declare var $;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  constructor(
    @Inject(PLATFORM_ID)
    private platformId: Object,
    private cdr: ChangeDetectorRef,
    private seo: SEOService,
    private translateService: TranslateService,
    private config: PrimeNGConfig
  ) {}

  ngOnInit(): void {
    /**
     * Initialize of SEO settings.
     */
    this.onInitSEO();

    /**
     * Initialize of I18N settings.
     */
    this.onInitI18n();

    /**
     * Initialize of navbar theme on scroll
     */
    this.onNavbarThemeScroll();
  }

  onInitSEO(): void {
    this.seo.init();
  }

  onNavbarThemeScroll(): void {
    $(window).on("scroll", function () {
      if ($(this).scrollTop() > 60) {
        $(".topbar-nav .navbar").addClass("bg-darker");
      } else {
        $(".topbar-nav .navbar").removeClass("bg-darker");
      }
    });
  }

  onInitI18n(): void {
    this.translateService.addLangs(["ru-RU", "en-EN"]);
    this.translateService.setDefaultLang("ru-RU");
    this.translateService.get("primeng").subscribe((res) => this.config.setTranslation(res));
  }
}
