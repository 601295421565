import { Component, Injector, HostListener, ChangeDetectorRef } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

export enum DeviceTypes {
  Mobile, // 0
  Tablet, // 1
  Desktop, // 2
}

@UntilDestroy()
@Component({
  template: "",
})
export class DevicesClass {
  public cdr: ChangeDetectorRef;
  public deviceSubject: BehaviorSubject<number> = new BehaviorSubject<any>(0);
  public deviceValue: BehaviorSubject<DeviceTypes> = new BehaviorSubject<DeviceTypes>(this.getUserDevice());
  public DTypes = DeviceTypes;

  constructor(injector: Injector) {
    this.cdr = injector.get(ChangeDetectorRef);
    this.onUserDevice();
  }

  @HostListener("window:resize", ["$event"])
  onResizeEvent($event): void {
    this.deviceSubject.next(this.getUserDevice());
  }

  onUserDevice(): void {
    this.deviceSubject.pipe(debounceTime(0), untilDestroyed(this)).subscribe((value) => {
      this.deviceValue.next(this.getUserDevice());
    });
  }

  mobileUserAgent(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  getUserDevice(): DeviceTypes {
    if (window.innerWidth < 768) {
      return 0;
    }
    if (window.innerWidth >= 768 && this.mobileUserAgent()) {
      return 1;
    }
    if (window.innerWidth >= 768 && !this.mobileUserAgent()) {
      return 2;
    }
    return 2;
  }
}
