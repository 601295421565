import { Injectable } from "@angular/core";
import { StorageService } from "@app/core/services/storage/storage.service";
import { StorageRoles } from "@app/core/constants";
import { IAccountUserInfo, IAccountUserTypes } from "@app/modules/account-module/account.model";

@Injectable()
export class RolesService {
  constructor(private storage: StorageService) {}

  /**
   * Setter for current roles.
   * @param value
   * @param remember
   */
  onRoles(value: Array<IAccountUserTypes>, remember: boolean = true) {
    this.storage.setStorage(
      {
        key: StorageRoles.roles,
        value: value,
      },
      remember ? "local" : "session"
    );
  }

  /**
   * Getter for current roles.
   */
  get roles(): Array<IAccountUserTypes> {
    const rolesStorage =
      this.storage.getStorage({ key: StorageRoles.roles }, "local") ||
      this.storage.getStorage({ key: StorageRoles.roles }, "session") ||
      "[]";
    return JSON.parse(rolesStorage);
  }

  /**
   * Checking of UserRole on User
   * @param user
   */
  onUserRole(user?: IAccountUserInfo): boolean {
    if (user) {
      return user && user.roles.some((role: IAccountUserTypes) => role === "ROLE_USER");
    }
    return this.roles.some((role: IAccountUserTypes) => role === "ROLE_USER");
  }

  /**
   * Checking of UserRole on Admin
   * @param user
   */
  onAdminRole(user?: IAccountUserInfo): boolean {
    if (user) {
      return user && user.roles.some((role: IAccountUserTypes) => role === "ROLE_ADMIN");
    }
    return this.roles.some((role: IAccountUserTypes) => role === "ROLE_ADMIN");
  }

  /**
   * Checking of UserRole on Moderator
   * @param user
   */
  onModeratorRole(user?: IAccountUserInfo): boolean {
    if (user) {
      return user && user.roles.some((role: IAccountUserTypes) => role === "ROLE_MODERATOR");
    }
    return this.roles.some((role: IAccountUserTypes) => role === "ROLE_MODERATOR");
  }
}
