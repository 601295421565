import { AppPrefix } from "@app/app.settings";
import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import {
  SetCookieInterface,
  DeleteCookieInterface,
  SetStorageInterface,
  GetStorageInterface,
  RemoveStorageInterface,
} from "./storage.model";
import { BaseLocalStorage } from "@app/core/lib/storage/storages/base-local.storage";
import { BaseSessionStorage } from "@app/core/lib/storage/storages/base-session.storage";

@Injectable()
export class StorageService {
  private $storage: string = "local";

  constructor(
    private cookieService: CookieService,
    private local: BaseLocalStorage,
    private session: BaseSessionStorage
  ) {}

  /**
   * Writes down values in Cookies.
   * @params{
   *   name: string,
   *   value: any
   *   expires: number
   *   path: string
   * }
   */
  setCookie(options: SetCookieInterface): void {
    options.name = this.prefixValue(options.name);

    this.cookieService.set(options.name, this.convertValue(options.value), options.expires || 30, options.path || "/");
  }

  /**
   * Get value from cookies by key.
   * If empty value, then will return empty string.
   * @params{
   *   key: string
   * }
   */
  getCookie(key: string): string {
    return this.cookieService.get(this.prefixValue(key)) || "";
  }

  /**
   * Checking value of cookie.
   * @params{
   *   key: string
   * }
   */
  checkCookie(key: string): boolean {
    return this.cookieService.check(this.prefixValue(key));
  }

  /**
   * Remove all cookies.
   * Return Promise.
   * @params{
   *   key: string
   * }
   */
  deleteCookie(options: DeleteCookieInterface): void {
    options.name = this.prefixValue(options.name);

    this.cookieService.delete(options.name, options.path || "/", options.domain || "");
  }

  /**
   * Remove all cookies.
   * Return Promise.
   * @params{
   *   key: string
   * }
   */
  deleteAllCookie(path?: string): void {
    this.cookieService.deleteAll(path || "/");
  }

  /**
   * Writes down values in Local Storage.
   * Return Promise.
   * @params{
   *   key: string,
   *   value: any,
   *   storage: string,
   * }
   */
  setStorage(options: SetStorageInterface, storage: string = this.$storage): void {
    if (storage === "local") {
      this.local.setItem(this.prefixValue(options.key), this.convertValue(options.value));
    }
    if (storage === "session") {
      this.session.setItem(this.prefixValue(options.key), this.convertValue(options.value));
    }
  }

  /**
   * Get value from local storage by key.
   * If empty value, then will return empty object as string
   * @params{
   *   key: string,
   *   storage: string,
   * }
   */
  getStorage(options: GetStorageInterface, storage = this.$storage): any {
    if (storage === "local") {
      return this.local.getItem(this.prefixValue(options.key)) || null;
    }
    if (storage === "session") {
      return this.session.getItem(this.prefixValue(options.key)) || null;
    }
  }

  /**
   * Get value from local storage by key.
   * If empty value, then will return empty object as string
   * @params{
   *   key: string,
   *   storage: string,
   * }
   */
  removeStorage(options: RemoveStorageInterface, storage: string = this.$storage): void {
    if (storage === "local") {
      return this.local.removeItem(this.prefixValue(options.key)) || {};
    }
    if (storage === "session") {
      return this.session.removeItem(this.prefixValue(options.key)) || {};
    }
  }

  /**
   * Clear all values from session storage.
   * @params{
   *   storage: string
   * }
   */
  clearStorage(storage: string = this.$storage): void {
    if (storage === "local") {
      return this.local.clear();
    }
    if (storage === "session") {
      return this.session.clear();
    }
  }

  /**
   * Converting any values in string.
   */
  private convertValue(value: any): string {
    return typeof value !== "string" ? JSON.stringify(value) : value;
  }

  /**
   * Add prefix to value.
   * @param value
   */
  private prefixValue(value: string): string {
    return !!AppPrefix && typeof AppPrefix === "string" && AppPrefix.length
      ? AppPrefix.toLowerCase() + "-" + value
      : value;
  }
}
