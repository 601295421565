import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { RoutingConfig } from "./routing.config";

const routes: Routes = [
  {
    path: RoutingConfig.Shell,
    loadChildren: () => import("../components/shell/shell.module").then((m) => m.ShellModule),
  },
  {
    path: RoutingConfig.ShellScreen,
    loadChildren: () => import("../components/screen/screen.module").then((m) => m.ScreenModule),
  },
  {
    path: "**",
    redirectTo: RoutingConfig.PageErrors + "/" + RoutingConfig.Page404,
    pathMatch: "full",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      { useHash: true }
      // {
      //   preloadingStrategy: PreloadAllModules,
      //   scrollPositionRestoration: 'enabled'
      // }
    ),
  ],
  exports: [RouterModule],
})
export class RoutingModule {}
