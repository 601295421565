import { Component, Injector } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  template: "",
})
export class ResolverClass {
  public route: ActivatedRoute;
  public preloadData: any;

  constructor(injector: Injector) {
    this.route = injector.get(ActivatedRoute);
    this.onPreloadData();
  }

  onPreloadData(): void {
    this.route.data.pipe(untilDestroyed(this)).subscribe((value) => {
      this.preloadData = value.data;
    });
  }
}
