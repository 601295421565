import { AppApiURL } from "@app/app.settings";
import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { TokenService } from "@app/core/services";
import { HttpRequestToken } from "@app/core/classes";
import { Observable, throwError } from "rxjs";
import { catchError, map, timeout } from "rxjs/operators";

import { IAccountUserInfo } from "@app/modules/account-module/account.model";

@Injectable()
export class AccountService {
  constructor(private http: HttpClient, private token: TokenService) {}

  /**
   * Api: v01.
   * Method: Auth SignIn.
   * @param request
   */
  public accountUser(): Observable<IAccountUserInfo> {
    return this.http.get<IAccountUserInfo>(AppApiURL + "/api/test/user").pipe(
      // map((response: HttpResponse<any>) => response.body),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }
}
