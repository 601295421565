/**
 * Available routing URL's of customer.
 */
export const RoutingConfig = {
  /**
   * Shell path.
   */
  Shell: "",
  ShellScreen: "",

  /**
   * Base pages
   */
  Auth: "auth",
  Dashboard: "dashboard",
  Schedule: "schedule",
  Finance: "finance",
  PickPoints: "pick-points",
  Staff: "staff",
  Settings: "settings",

  /**
   * Login Routing
   */
  SignIn: "sign-in",
  SignUp: "sign-up",
  SignOAuth: "sign-oauth",
  SignRemind: "sign-remind",
  SignSuccess: "sign-success",

  /**
   * Dashboard Routing
   */
  BaseChart: "base-chart",

  /**
   * Schedules Routing
   */
  ScheduleCalendar: "schedule-calendar",

  /**
   * PickPoints Routing
   */
  PickPointsLists: "lists",

  /**
   * PickPoints Routing
   */
  FinanceLists: "lists",
  FinanceComparison: "comparison",
  FinancePayback: "payback",

  /**
   * Staff Routing
   */
  StaffLists: "lists",

  /**
   * Settings Routing
   */
  SettingsLists: "lists",

  /**
   * Errors
   */
  PageErrors: "errors",
  Page404: "404",
  PagePermission: "permission",
};
