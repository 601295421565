import { environment } from "../environments/environment";

/**
 * Prefix of current application.
 */
export const AppPrefix = "pickup-points";

/**
 * Variables from EVN config.
 */
export const AppPROD = environment.production;
export const AppApiURL = environment.apiUrl;
