import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";

/**
 * Services
 */
import { AccountService } from "@app/modules/account-module/account.service";

@NgModule({
  declarations: [],
  exports: [],
  imports: [
    // RouterModule.forChild(PickpointsRoutes),
    CommonModule,
    ReactiveFormsModule,
  ],
  providers: [AccountService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AccountModule {}
