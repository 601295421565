import { AppApiURL } from "@app/app.settings";
import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { RolesService, TokenService } from "@app/core/services";
import { HttpRequestToken } from "@app/core/classes";
import { Observable, throwError } from "rxjs";
import { catchError, map, timeout } from "rxjs/operators";
import { RoutingConfig } from "@app/routing/routing.config";
import { ActivatedRoute, Router } from "@angular/router";

import { IAuthSignInReq, IAuthSignInRes, IAuthTokenRefreshRequest } from "@app/modules/auth-module/auth.model";

@Injectable()
export class AuthService {
  constructor(
    private http: HttpClient,
    private token: TokenService,
    private roles: RolesService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  /**
   * Api: v01.
   * Method: Auth SignIn.
   * @param request
   */
  public signIn(request: IAuthSignInReq): Observable<IAuthSignInRes> {
    return this.http
      .post<IAuthSignInReq>(AppApiURL + "/api/auth/signIn", request, {
        observe: "response",
        params: new HttpRequestToken(true),
      })
      .pipe(
        map((response: HttpResponse<any>) => response.body),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  /**
   * Api: v01.
   * Method: Auth Tokens Refresh.
   * @param request
   */
  public tokensRefresh(request: IAuthTokenRefreshRequest): Observable<any> {
    return this.http
      .post<IAuthTokenRefreshRequest>(AppApiURL + "/api/auth/refreshToken", request, {
        params: new HttpRequestToken(true),
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  /**
   * Method: Callback for API Auth SignIn or SignUp.
   * @param response
   * @param remember
   */
  public onAuthRequestSuccess(response: IAuthSignInRes, remember: boolean = true): void {
    /**
     * Set of Access Token.
     * @type string
     */
    if (response.accessToken) {
      this.token.onAccessToken(response.accessToken, remember);
    }

    /**
     * Set of Refresh Token.
     * @type string
     */
    if (response.refreshToken) {
      this.token.onRefreshToken(response.refreshToken, remember);
    }

    /**
     * Set of User Roles.
     * @type string
     */
    if (response.roles) {
      this.roles.onRoles(response.roles, remember);
    }
  }

  /**
   * Method: Callback for API Auth Refresh Token.
   * @redirect
   */
  public onAuthLogout(redirect?: boolean): void {
    const redirectUrl = this.route["_routerState"]["snapshot"]["url"];

    /**
     * Redirect to selected URL.
     */
    redirect
      ? this.router
          .navigateByUrl(
            this.router.createUrlTree([RoutingConfig.Auth + "/" + RoutingConfig.SignIn], {
              queryParams: {
                redirectUrl,
              },
            })
          )
          .then()
      : this.router.navigateByUrl(RoutingConfig.Auth + "/" + RoutingConfig.SignIn).catch();

    /**
     * Clear token's data.
     */
    this.token.onAccessTokenClear();
    this.token.onRefreshTokenClear();
  }
}
