/**
 * Angular.
 */
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { APP_BASE_HREF } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { HttpClient, HttpClientModule } from "@angular/common/http";

/**
 * Base component
 */
import { AppComponent } from "./app.component";

/**
 * Modules.
 */
import { RoutingModule } from "@app/routing/routing.module";
import { StorageModule } from "@app/core/lib/storage";
import { CoreModule } from "@app/core/modules/core.module";
import { AccountModule } from "@app/modules/account-module/account.module";

/**
 * Vendors
 */
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

/**
 * i18n.json loader
 */
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({
      appId: "serverApp",
    }),
    BrowserAnimationsModule,
    RoutingModule,
    StorageModule.forRoot(),
    CoreModule,
    FormsModule,
    HttpClientModule,
    AccountModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useValue: "/",
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
